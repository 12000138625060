<template>
  <v-container id="footer-container">
    <v-row>
      <v-col class="col-12 col-sm-4 col-md">
        <p>TetaHQ</p>
        <p>{{ $i18n.t(`footer.goodIdeas`) }}😛</p>
      </v-col>
      <v-col class="col-12 col-sm-4 col-md">
        <a href="https://goo.gl/maps/q8EGKDrwx7Mwy2Mv7" target="_blank" class="cursor-pointer">
          <p>Hacettepe Teknokent,</p>
          <p>4. Ar-Ge Binası 95A/26</p>
          <p>Ankara, Türkiye</p>
        </a>
      </v-col>
      <v-col class="col-12 col-sm-4 col-md">
        <a href="tel:+903122275566"><p>{{ $i18n.t(`footer.phone`) }}: 312 227 55 66</p></a>
        <a href="mailto:hello@tetahq.com"><p>hello@tetahq.com</p></a>
      </v-col>
      <v-col class="col-12 col-sm-4 col-md">
        <router-link :to="item.url" v-for="item in leftMenu" :key="item.id"
                     class="cursor-pointer" tag="p">
          {{ $i18n.t(`footer.${item.key}`) }}
        </router-link>
      </v-col>
      <v-col class="col-12 col-sm-4 col-md">
        <router-link :to="item.url" v-for="item in rightMenu" :key="item.id"
                     class="cursor-pointer" tag="p">
          {{ $i18n.t(`footer.${item.key}`) }}
        </router-link>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <span>© {{ new Date().getFullYear() }} TETAHQ.COM {{ $i18n.t(`footer.allRightsReserved`) }}</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Footer",
  data: () => ({
    leftMenu: [
      {
        id: '0',
        key: 'products',
        title: 'TetaHQ Products Page',
        url: '/#products'
      },
      {
        id: '1',
        key: 'whoWeAre',
        title: 'Who is TetaHQ',
        url: '/#about'
      },
    ],
    rightMenu: [
      {
        id: '2',
        key: 'contactUs',
        title: 'Contact to TetaHQ',
        url: '/contact'
      },
      {
        id: '3',
        key: 'startProject',
        title: 'Do you want to make a project?',
        url: '/start-project'
      },
    ],
    languages: [
      {
        id: 'tr',
        name: 'TR',
      },
      {
        id: 'en',
        name: 'EN',
      },
    ]
  })
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables.scss";

#footer-container {
  margin-top: 220px;
}

p {
  color: $color-white;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 24px;
}

span {
  color: $color-light-blue;
  font-size: 16px;
}

@media (max-width: 1260px) {
  p {
    font-size: 16px;
  }
  span {
    font-size: 14px;
  }
}

@media (max-width: 960px) {
  #footer-container {
    border-top: 1px solid $color-gray;
    text-align: center;
  }
}
</style>
