import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import i18n from './i18n';
import config from "@/config";
import {VueReCaptcha} from 'vue-recaptcha-v3'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.config.productionTip = false

Vue.use(VueReCaptcha, {siteKey: config.grecaptcha.siteKey});
Vue.use(VueAxios, axios);

new Vue({
    router,
    vuetify,
    i18n,
    render: h => h(App),
}).$mount('#app');
