<template>
  <v-container>
    <div class="home-description-row">
      <h1 v-html="$i18n.t(`home.firstText`)"/>

      <div class="image-wrapper">
        <v-img src="@/assets/images/tetahq-icon.svg" sizes="506" alt="TetaHQ" transition="slide-y-transition"/>
      </div>
    </div>

    <div id="products">
      <ProductCard
          button-href="https://teta.design"
          :description=" $i18n.t(`home.tetaDesignDescription`) "
          image-filename="tetadesign-preview.png"
          logo-filename="tetadesign-logo.svg"
          :title="$i18n.t(`home.tetadesign`)"
          button-color="#3D5AFE" image-alt="Teta Design"
      />

      <ProductCard
          button-href="https://tetawood.com"
          :description=" $i18n.t(`home.tetawoodDescription`) "
          image-filename="tetawood-preview.png"
          logo-filename="tetawood-logo.svg"
          :title="$i18n.t(`home.tetawood`)"
          button-color="#E61873" image-alt="Tetawood"
      />
    </div>

    <div id="about">
      <v-row class="home-middle-text">
        <v-col class="col-12 col-md-7">
          <h2>{{ $i18n.t(`home.middleTextLeft`) }}</h2>
        </v-col>
        <v-col class="col-12 col-md-5">
          <h3>{{ $i18n.t(`home.middleTextRight`) }}</h3>
        </v-col>
      </v-row>
    </div>

    <Header class="home-header-component" :title="$i18n.t(`home.lastText`)" start-project-button title-size="72"/>

  </v-container>
</template>

<script>
import ProductCard from "@/components/ProductCard";
import Header from "@/components/Header";

export default {
  name: "home",
  components: {Header, ProductCard}
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables.scss";

/*Description Row*/

.home-description-row {
  margin-top: 40px;
  position: relative;
  height: 500px;
  display: flex;
  align-items: center;
}

.home-description-row h1 {
  position: absolute;
  z-index: 1;
  color: $color-white;
  font-size: 72px;
  font-weight: lighter;
  line-height: 91px;
}

.image-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  width: 100%;
  max-width: 500px;
}

@media (max-width: 960px) {
  .home-description-row h1 {
    color: $color-white;
    font-size: 30px;
    font-weight: lighter;
    line-height: 44px;
  }
  .home-description-row {
    height: 300px;
  }
  .image-wrapper {
    max-width: 300px;
  }
}

/* Middle Text */
.home-middle-text {
  margin-top: 256px;
}

.home-middle-text h2 {
  color: $color-white;
  font-size: 54px;
  font-weight: lighter;
  line-height: 64px;
  max-width: 710px;
}

.home-middle-text h3 {
  color: $color-smoke;
  font-size: 20px;
  font-weight: 300;
  line-height: 32px;
}

@media (max-width: 960px) {
  .home-middle-text {
    margin-top: 100px;
  }
  .home-middle-text h2 {
    font-size: 26px;
    line-height: 32px;
  }
  .home-middle-text h3 {
    font-size: 18px;
  }
}

.home-header-component {
  margin-top: 200px;
}

@media (max-width: 960px) {
  .home-header-component {
    margin-top: 100px;
  }
}
</style>
