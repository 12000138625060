<template>
  <v-flex class="header-row d-flex flex-column text-center justify-center align-center">
    <span :style="`font-size:${this.$props.titleSize}px`" v-html="$props.title"/>

    <router-link to="/start-project" v-if="$props.startProjectButton === true">
      <v-btn class="start-project-button" x-large rounded ripple>
        {{ $i18n.t(`home.startProject`) }}
        <v-img src="@/assets/images/chevron-right.svg" class="button-chevron"/>
      </v-btn>
    </router-link>

    <div class="image-wrapper">
      <v-img src="@/assets/images/tetahq-icon.svg" height="350" alt="TetaHQ" contain position="center" transition="slide-y-transition"/>
    </div>
  </v-flex>

</template>

<script>
export default {
  name: "Header",
  props: {
    title: {
      type: String,
      required: true
    },
    titleSize: {
      type: String,
    },
    startProjectButton: {
      type: Boolean,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables.scss";

span {
  color: $color-white;
  font-weight: lighter;
  z-index: 1;
}

.header-row {
  margin: 40px 0;
  position: relative;
  height: 350px;
}

.image-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 0;
  width: 100%;
  max-width: 350px;
}

.start-project-button {
  color: $color-red;
  font-size: 24px;
  z-index: 1;
  margin-top: 72px;
}

.button-chevron {
  margin-left: 15px
}

@media (max-width: 1260px) {
  span {
    font-size: 64px!important;
  }
  .image-wrapper {
    max-width: 300px;
  }
  .start-project-button {
    margin-top: 24px;
  }
}

@media (max-width: 960px) {
  span {
    font-size: 30px!important;
  }
  .start-project-button {
    margin-top: 12px;
  }
}
</style>
