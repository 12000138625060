import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from '@/i18n'
import '@/assets/scss/overrides.scss'

Vue.use(Vuetify);

export default new Vuetify({
    options: {
        customProperties: true
    },
    lang: {
        t: (key, ...params) => i18n.t(key, params),
    },
})
