<template>
  <div id="NavigationBar">
    <v-container>
      <v-row align="center" id="navigation-row">

        <!-- Logo -->
        <v-col class="col-md-2 col-8" align-self="center">
          <router-link to="/">
            <v-img src="@/assets/images/tetahq-logo.svg" alt="TetaHQ" max-height="64" sizes="275" position="left"
                   contain/>
          </router-link>
        </v-col>

        <v-col class="col-md-10 col-4 d-flex justify-end">

          <div class="hidden-sm-and-down">
            <!-- Navigation Items -->
            <router-link :to="item.url" v-for="item in nav" :key="item.id"
                         class="nav-items">
              {{ $i18n.t(`navigation.${item.key}`) }}
            </router-link>

            <!-- Languages -->
            <span @click="changeLocale( $i18n.locale === 'en' ? 'tr' : 'en')"
                  class="white--text font-weight-bold margined-nav-item text-uppercase cursor-pointer">
                {{ $i18n.locale === 'en' ? 'TR' : 'EN' }}
            </span>

          </div>

          <!-- Hamburger Icon -->
          <div class="hamburger" @click="toggle = !toggle"
               :class="`${toggle ? 'hamburger--is-open' : ''} hidden-md-and-up`">
            <div class="hamburger__item hamburger__item--first"></div>
            <div class="hamburger__item hamburger__item--middle"></div>
            <div class="hamburger__item hamburger__item--last"></div>
          </div>

          <!-- Hamburger Menu -->
          <v-dialog
              v-model="toggle"
              hide-overlay
              transition="dialog-bottom-transition"
              scrollable
              content-class="v-dialog-custom hidden-md-and-up"
          >

            <!-- Dropdown -->
            <v-list class="d-flex flex-column justify-space-between">
              <!-- Navigation Items -->
              <div>
                <v-list-item v-for="item in nav" :key="item.id" @click="toggle = false">
                  <router-link :to="item.url" class="nav-items-mobile">
                    {{ $i18n.t(`navigation.${item.key}`) }}
                  </router-link>
                </v-list-item>
              </div>
              <!-- Languages -->

              <v-container class="nav-items-mobile-footer">
                <v-row>
                  <v-col class="col-9" @click="toggle = false">
                    <router-link :to="nav[3].url" class="nav-items-mobile-bordered">
                      {{ $i18n.t(`navigation.${nav[3].key}`) }}
                    </router-link>
                  </v-col>

                  <v-col class="text-right col-3">
                    <span @click="changeLocale( $i18n.locale === 'en' ? 'tr' : 'en')"
                          class="white--text margined-nav-item text-uppercase cursor-pointer mr-2">
                          {{ $i18n.locale === 'en' ? 'TR' : 'EN' }}
                    </span>
                  </v-col>
                </v-row>
              </v-container>
            </v-list>

          </v-dialog>

        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "NavigationBar",
  methods: {
    changeLocale: function (locale) {
      this.$i18n.locale = locale
      localStorage.setItem("i18n-language", locale);
    },
  },
  data: () => ({
    toggle: false,
    nav: [
      {
        id: '0',
        key: 'products',
        title: 'TetaHQ Products Page',
        url: '/#products'
      },
      {
        id: '1',
        key: 'whoWeAre',
        title: 'Who is TetaHQ',
        url: '/#about'
      },
      {
        id: '2',
        key: 'contactUs',
        title: 'Contact to TetaHQ',
        url: '/contact'
      },
      {
        id: '3',
        key: 'startProject',
        title: 'Do you want to make a project?',
        url: '/start-project'
      },
    ],
    languages: [
      {
        id: 'tr',
        name: 'TR',
      },
      {
        id: 'en',
        name: 'EN',
      },
    ]
  })
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables";

#navigation-row {
  height: 176px;
  font-size: 20px;
}

.nav-items {
  color: $color-smoke !important;
  margin: 0 27px;
}

.nav-items:hover {
  color: $color-white !important;
}

.nav-items:last-of-type {
  border-radius: 100px;
  padding: 16px 32px;
  border: 1px solid $color-smoke !important;
}

.nav-items:last-of-type:hover {
  border-color: $color-white !important;
}

.nav-items-mobile {
  color: $color-smoke !important;
  margin: 15px 0;
  padding: 0 25px;
  text-align: center;
  font-size: 24px;
}

.nav-items-mobile-bordered {
  color: $color-smoke !important;
  font-size: 16px;
  border-radius: 100px;
  padding: 16px 32px;
  border: 1px solid $color-smoke !important;
}

.router-active.router-exact-active {
  color: $color-white !important;
}

.nav-items-mobile.router-active.router-exact-active {
  color: $color-white !important;
}

.margined-nav-item {
  margin-left: 27px;
}

.nav-items-mobile-footer {
  border-top: 1px solid $color-gray;
  padding-top: 47px;
  padding-bottom: 32px;
}

@media (max-width: 1260px) {
  #navigation-row {
    font-size: 14px;
  }
}

@media (max-width: 960px) {
  #navigation-row {
    height: 80px;
    border-bottom: 1px solid $color-gray;
  }
}

/*Hamburger Menu*/
.hamburger {
  height: 24px;
  width: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: auto;
  margin-right: 4px;

  &:hover {
    cursor: pointer;
  }

  &__item {
    height: 3px;
    width: 100%;
    background: $color-smoke;
    transition: transform 300ms cubic-bezier(0.445, 0.05, 0.55, 0.95),
    opacity 300ms linear;

    &--first {
      .hamburger--is-open & {
        background: $color-white;
        transform: translate(0, 10px) rotate(45deg);
      }
    }

    &--middle {
      width: 26px;
      margin-left: -3px;

      .hamburger--is-open & {
        opacity: 0;
      }
    }

    &--last {
      .hamburger--is-open & {
        background: $color-white;
        transform: translate(0, -11px) rotate(-45deg);
      }
    }
  }
}
</style>
