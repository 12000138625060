import Vue from 'vue';
import Router from 'vue-router';
import Home from '@/views/home';
import Contact from '@/views/contact';
import StartProject from '@/views/startProject';

Vue.use(Router);

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    linkActiveClass: "router-active",
    linkExactActiveClass: "router-exact-active",
    routes: [
        {
            path: '/',
            component: Home,
            name: 'Home',
        },
        {
            name: 'Contact',
            path: '/contact',
            component: Contact,
        },
        {
            name: 'Start A Project',
            path: '/start-project',
            component: StartProject,
        },
        {
            path: '*',
            redirect: '/',
        },
    ],
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                selector: to.hash,
                behavior: 'smooth',
            }
        }
        if (savedPosition) {
            return savedPosition
        } else {
            return {x: 0, y: 0}
        }
    }
})
