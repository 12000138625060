<template>
  <div class="product-card-row">
    <v-row>

      <v-col class="col-12 col-lg-5 col-xl-7">
        <v-img :src="require(`@/assets/images/${$props.logoFilename}`)" height="54" position="left"
               :alt="$props.imageAlt" transition="slide-y-transition" contain/>
        <h2>{{ $props.title }}</h2>
        <p>{{ $props.description }}</p>
        <v-btn :color="$props.buttonColor" :href="$props.buttonHref" target="_blank" dark large rounded>
          {{ $i18n.t(`home.seeProject`) }}
        </v-btn>
      </v-col>

      <v-col class="col-12 col-lg-7 col-xl-5 relative">
        <div class="product-card-image-wrapper">
          <v-img :src="require(`@/assets/images/${$props.imageFilename}`)" :alt="$props.imageAlt" position="center"
                 transition="slide-y-transition" contain/>
        </div>
      </v-col>

    </v-row>
  </div>
</template>

<script>
export default {
  name: "ProductCard",
  props: {
    logoFilename: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    buttonHref: {
      type: String,
      required: true
    },
    buttonColor: {
      type: String,
      required: true
    },
    imageFilename: {
      type: String,
      required: true
    },
    imageAlt: {
      type: String,
      required: true
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables.scss";

.product-card-row {
  position: relative;
  width: 100%;
  height: 464px;
  background: $color-dark-blue;
  border-radius: 24px;
  padding: 56px;
  margin-top: 210px;
}

.product-card-row h2 {
  margin-top: 40px;
  color: $color-white;
  font-size: 38px;
  font-weight: 400;
}

.product-card-row p {
  margin-top: 24px;
  color: $color-smoke;
  font-size: 20px;
  font-weight: 300;
  line-height: 32px;
  max-width: 621px;
}

.product-card-row a {
  margin-top: 40px;
}

.product-card-image-wrapper {
  position: absolute;
  top: -43px;
  right: -65px;
  width: 726px;
}

@media (max-width: 960px) {
  .product-card-row {
    height: auto;
    margin-top: 110px;
    padding: 24px;
  }
}

@media (max-width: 1260px) {
  .product-card-row {
    height: auto;
  }
  .product-card-image-wrapper {
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
  .product-card-row p {
    max-width: 100%;
  }
}

@media (max-width: 1903px) {
  .product-card-row h2 {
    font-size: 30px;
  }

  .product-card-row p {
    font-size: 16px;
  }
}
</style>
