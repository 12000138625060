<template>
  <v-container>
    <Header class="home-header-component" :title="$i18n.t(`contact.getInTouch`)" title-size="144"/>

    <div class="d-flex flex-column justify-center text-center contact-row">
      <p class="smoke-text">{{ $i18n.t(`contact.contact`) }}</p>
      <a href="tel:+903122275566"><p>{{ $i18n.t(`footer.phone`) }}: 312 227 55 66</p></a>
      <a href="mailto:hello@tetahq.com"><p>hello@tetahq.com</p></a>
    </div>

    <v-row class="contact-form-row">

      <v-col class="col-12 col-md-6 pr-12">
        <span class="contact-form-description">{{ $i18n.t(`contact.contactFromDescription`) }}</span>
      </v-col>

      <v-col class="col-12 col-md-6">
        <v-form
            ref="form"
            v-model="valid"
            @submit.prevent="submit"
        >

          <v-text-field
              v-model="name"
              :rules="nameRules"
              :label="$i18n.t(`contact.yourName`)"
              dark
              required
          ></v-text-field>

          <v-text-field
              v-model="email"
              :rules="emailRules"
              :label="$i18n.t(`contact.mailAddress`)"
              dark
              required
          ></v-text-field>

          <v-textarea
              v-model="content"
              :rules="contentRules"
              :label="$i18n.t(`contact.yourMessage`)"
              counter="3000"
              no-resize
              dark
              required
          />

          <v-btn v-if="!sent && !error" :loading="sending" class="submit-button mt-16"
                 @click="submit"
                 x-large rounded outlined ripple>
            {{ $i18n.t(`contact.sendMessage`) }}
          </v-btn>

          <v-btn v-if="sent" class="green--text mt-16" x-large rounded>
            {{ $i18n.t(`contact.messageSent`) }}
            <v-img src="@/assets/images/success-check.svg"/>
          </v-btn>

          <v-btn v-if="error" class="red--text mt-16" @click="errorReset" x-large rounded>
            {{ $i18n.t(`contact.messageError`) }}
            <v-img src="@/assets/images/error-outline.svg"/>
          </v-btn>

        </v-form>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import Header from "@/components/Header";
import config from "@/config";

export default {
  name: "contact",
  components: {
    Header,
  },
  data: function () {
    return {
      sent: false,
      sending: false,
      error: false,
      valid: false,

      name: null,
      nameRules: [
        text => !!text || this.$i18n.t(`contact.required`),
        text => (text && text.length <= 250) || this.$i18n.t(`contact.maxCharacter`, {number: 250})
      ],
      email: null,
      emailRules: [
        text => !!text || this.$i18n.t(`contact.required`),
        text => /.+@.+\..+/.test(text) || this.$i18n.t(`contact.valid`),
        text => (text && text.length <= 250) || this.$i18n.t(`contact.maxCharacter`, {number: 250})
      ],
      content: null,
      contentRules: [
        text => !!text || this.$i18n.t(`contact.required`),
        text => (text && text.length >= 10) || this.$i18n.t(`contact.minCharacter`, {number: 10}),
        text => (text && text.length <= 3000) || this.$i18n.t(`contact.maxCharacter`, {number: 3000})
      ],
    }
  },
  computed: {
    form() {
      return {
        name: this.name,
        email: this.email,
        content: this.content,
      }
    },
  },
  methods: {
    errorReset() {
      this.error = false;
      this.sent = false;
      this.formValidate();
    },
    formValidate() {
      this.$refs.form.validate();
    },
    formReset() {
      this.$refs.form.reset();
    },
    async completeSubmit() {
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha('login');

      const response = await this.axios.post(config.grecaptcha.contactFormUrl, {
        ...this.form,
        "g-recaptcha-response": token
      });

      if (response.status === 201) {
        this.sent = true;
        this.formReset();
        this.error = false;
      } else {
        this.error = true;
      }

    },
    async submit() {
      this.sending = true;
      this.formValidate();
      if (this.valid) {
        await this.completeSubmit();
      }
      this.sending = false;
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables.scss";

.contact-row p {
  font-size: 24px;
  color: $color-white;
}

.contact-form-row {
  margin-top: 220px;
}

.contact-form-description {
  color: $color-white;
  font-size: 54px;
  font-weight: lighter;
  line-height: 72px;
}

.submit-button {
  color: $color-smoke;
  font-size: 18px;
  z-index: 1;
}

@media (max-width: 960px) {
  .contact-form-description {
    font-size: 24px;
    line-height: 32px;
  }
  .contact-form-row {
    margin-top: 100px;
  }
}
</style>
