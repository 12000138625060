<template>
  <v-container>
    <Header class="home-header-component" :title="$i18n.t(`startProject.startProject`)" title-size="115"/>

    <div class="d-flex flex-column justify-center align-center text-center start-project-row">
      <p class="description-text text-center">{{ $i18n.t(`startProject.description`) }}</p>
    </div>

    <v-form
        ref="form"
        v-model="valid"
        @submit.prevent="submit"
    >
      <v-row class="start-project-form" align="center">
        <v-col class="text-md-right col-12 col-md-4">{{ $i18n.t(`startProject.nameInputLeft`) }}</v-col>
        <v-col class="col-12 col-md-4">
          <v-text-field
              v-model="name"
              :rules="nameRules"
              :label="$i18n.t(`startProject.nameInput`)"
              dark
              required
          ></v-text-field>
        </v-col>
        <v-col class="col-12 col-md-4">{{ $i18n.t(`startProject.nameInputRight`) }}</v-col>
      </v-row>

      <v-row class="start-project-form" align="center">
        <v-col class="text-md-right col-12 col-md-4">{{ $i18n.t(`startProject.companyInputLeft`) }}</v-col>
        <v-col class="col-12 col-md-4">
          <v-text-field
              v-model="company"
              :rules="companyRules"
              :label="$i18n.t(`startProject.companyInput`)"
              dark
              required
          ></v-text-field>
        </v-col>
        <v-col class="col-12 col-md-4">{{ $i18n.t(`startProject.companyInputRight`) }}</v-col>
      </v-row>

      <v-row class="start-project-form" align="center">
        <v-col class="text-md-right col-12 col-md-4">{{ $i18n.t(`startProject.goalInputLeft`) }}</v-col>
        <v-col class="col-12 col-md-4">
          <v-text-field
              v-model="goal"
              :rules="goalRules"
              :label="$i18n.t(`startProject.goalInput`)"
              dark
              required
          ></v-text-field>
        </v-col>
        <v-col class="col-12 col-md-4">{{ $i18n.t(`startProject.goalInputRight`) }}</v-col>
      </v-row>

      <v-row class="start-project-form" align="center">
        <v-col class="text-md-right col-12 col-md-4">{{ $i18n.t(`startProject.dateInputLeft`) }}</v-col>
        <v-col class="col-12 col-md-4">
          <v-text-field
              v-model="date"
              :rules="dateRules"
              :label="$i18n.t(`startProject.dateInput`)"
              dark
              required
          ></v-text-field>
        </v-col>
        <v-col class="col-12 col-md-4">{{ $i18n.t(`startProject.dateInputRight`) }}</v-col>
      </v-row>

      <v-row class="start-project-form" align="center">
        <v-col class="text-md-right col-12 col-md-4">{{ $i18n.t(`startProject.selectInputLeft`) }}</v-col>
        <v-col class="col-12 col-md-4">
          <v-select
              v-model="budget"
              :rules="budgetRules"
              :items="prices"
              :label="$i18n.t(`startProject.selectInput`)"
              dark
              required
          ></v-select>
        </v-col>
        <v-col class="col-12 col-md-4">{{ $i18n.t(`startProject.selectInputRight`) }}</v-col>
      </v-row>

      <v-row class="start-project-form" align="center">
        <v-col class="text-md-right col-12 col-md-4">{{ $i18n.t(`startProject.mailInputLeft`) }}</v-col>
        <v-col class="col-12 col-md-4">
          <v-text-field
              v-model="email"
              :rules="emailRules"
              :label="$i18n.t(`startProject.mailInput`)"
              dark
              required
          ></v-text-field>
        </v-col>
        <v-col class="col-12 col-md-4">{{ $i18n.t(`startProject.mailInputRight`) }}</v-col>
      </v-row>

      <v-row class="start-project-form" align="start">
        <v-col class="text-md-right col-12 col-md-4">{{ $i18n.t(`startProject.detailsInputLeft`) }}</v-col>
        <v-col class="col-12 col-md-4">
          <v-textarea
              v-model="content"
              :rules="contentRules"
              :label="$i18n.t(`startProject.detailsInput`)"
              counter="3000"
              no-resize
              dark
              required
          />
        </v-col>
        <v-col class="col-12 col-md-4">{{ $i18n.t(`startProject.detailsInputRight`) }}</v-col>
      </v-row>

      <v-row justify="center">
        <v-btn v-if="!sent && !error" :loading="sending" class="submit-button" @click="submit" x-large dark rounded outlined
               ripple>
          {{ $i18n.t(`startProject.sendMessage`) }}
        </v-btn>

        <v-btn v-if="sent" class="green--text" x-large rounded >
          {{ $i18n.t(`startProject.messageSent`) }}
          <v-img src="@/assets/images/success-check.svg"/>
        </v-btn>

        <v-btn v-if="error" class="red--text" @click="errorReset" x-large rounded>
          {{ $i18n.t(`startProject.messageError`) }}
          <v-img src="@/assets/images/error-outline.svg"/>
        </v-btn>

      </v-row>
    </v-form>
    <div id="recaptcha-container"></div>
  </v-container>
</template>

<script>
import Header from "@/components/Header";
import config from "@/config";

export default {
  name: "startProject",
  components: {Header},
  data: function () {
    return {
      sent: false,
      sending: false,
      error: false,
      valid: false,

      name: null,
      nameRules: [
        text => !!text || this.$i18n.t(`startProject.required`),
        text => (text && text.length <= 250) || this.$i18n.t(`startProject.maxCharacter`, {number: 250})
      ],
      company: null,
      companyRules: [
        text => !!text || this.$i18n.t(`startProject.required`),
        text => (text && text.length <= 250) || this.$i18n.t(`startProject.maxCharacter`, {number: 250})
      ],
      goal: null,
      goalRules: [
        text => !!text || this.$i18n.t(`startProject.required`),
        text => (text && text.length <= 250) || this.$i18n.t(`startProject.maxCharacter`, {number: 250})
      ],
      date: null,
      dateRules: [
        text => !!text || this.$i18n.t(`startProject.required`),
        text => (text && text.length <= 250) || this.$i18n.t(`startProject.maxCharacter`, {number: 250})
      ],
      budget: null,
      budgetRules: [
        text => !!text || this.$i18n.t(`startProject.required`),
        text => (text && text.length <= 250) || this.$i18n.t(`startProject.maxCharacter`, {number: 250})
      ],
      email: null,
      emailRules: [
        text => !!text || this.$i18n.t(`startProject.required`),
        text => /.+@.+\..+/.test(text) || this.$i18n.t(`startProject.valid`),
        text => (text && text.length <= 250) || this.$i18n.t(`startProject.maxCharacter`, {number: 250})
      ],
      content: null,
      contentRules: [
        text => !!text || this.$i18n.t(`startProject.required`),
        text => (text && text.length >= 10) || this.$i18n.t(`startProject.minCharacter`, {number: 10}),
        text => (text && text.length <= 3000) || this.$i18n.t(`startProject.maxCharacter`, {number: 3000})
      ],

      prices: [
        this.$i18n.t(`startProject.selectInputVal1`),
        this.$i18n.t(`startProject.selectInputVal2`),
        this.$i18n.t(`startProject.selectInputVal3`)
      ]
    }
  },
  computed: {
    form() {
      return {
        name: this.name,
        company: this.company,
        goal: this.goal,
        date: this.date,
        budget: this.budget,
        email: this.email,
        content: this.content,
      }
    },
  },
  methods: {
    errorReset() {
      this.error = false;
      this.sent = false;
      this.formValidate();
    },
    formValidate() {
      this.$refs.form.validate();
    },
    formReset() {
      this.$refs.form.reset();
    },
    async completeSubmit() {
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha('login');

      const response = await this.axios.post(config.grecaptcha.projectFormUrl, {
        ...this.form,
        "g-recaptcha-response": token
      });

      if (response.status === 201) {
        this.sent = true;
        this.formReset();
        this.error = false;
      } else {
        this.error = true;
      }

    },
    async submit() {
      this.sending = true;
      this.formValidate();
      if (this.valid) {
        await this.completeSubmit();
      }
      this.sending = false;
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables.scss";

.start-project-row {
  margin-top: 40px;
}

.start-project-form > .col {
  color: $color-smoke;
  font-size: 28px;
}

.description-text {
  color: $color-smoke;
  font-size: 20px;
  line-height: 32px;
  max-width: 900px;
}

</style>
